import React from "react"
import Layout from "../layouts/layout"
import Contact from "../components/contact-form/form"
import "../page-styles/contact.scss"

const IndexPage = () => {
  return (
    <Layout>
      <div className="contact-container">
        <Contact />
      </div>
    </Layout>
  )
}

export default IndexPage
